@tailwind base;
@tailwind components;
@tailwind utilities;
main{
  height: 100vh;
}
body {
  margin: 0;
  padding: 0;
  height: 150vh;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.content-container {
  min-height: calc(100vh - 34px);
}
.footer--pin {
  position: relative;
  left: 0;
  bottom: 0;
}
.cell{
  width:100px;
  height: 200px;
}
